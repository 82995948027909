import StorageIcon from '@mui/icons-material/Storage';
import { useEffect, useState } from 'react';
import { get, appUrl, getFormattedDate, timestampToDate, post } from '../../utils/fetch';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import "./Dashboard.css";
import Header from '../navigation/Header';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import TubeSubmit from '../form/TubeSubmit';
import { Avatar, Box, Dialog, IconButton } from '@mui/material';
import { DeleteOutlineOutlined, ModeOutlined, PlayArrowOutlined } from '@mui/icons-material';
import Video from './Video';
import DialogComponent from '../dialog/Dialog';
import Album from './Album';
import User from './User';
import { useDispatch } from 'react-redux';
import {edit as videosFilter} from "../../slices/videosFilterSlice.js";
import { useNavigate } from 'react-router-dom';
import {edit as albumsFilter} from '../../slices/albumsFilterSlice';
import {edit as channelsFilter} from '../../slices/channelsFilterSlice';
import {edit as contentCategoriesFilter} from '../../slices/contentCategoriesFilterSlice.js';

export default function Dashboard(){
    const [ data, setData ] = useState({
        php_version: "",
        local_time: "",
        queueCount: "",
        cpuUsage: "",
        diskSpace: [],
        videos_active: 0,
        videos_inactive: 0,
        albums_active: 0,
        albums_inactive: 0,
        models_active: 0,
        models_inactive: 0,
        channels_active: 0,
        channels_inactive: 0,
        categories_active: 0,
        categories_inactive: 0,
        tags_active: 0,
        tags_inactive: 0
    })
    const [ openEditVideo, setOpenEditVideo ] = useState(false);
    const [ videoId, setVideoId ] = useState(0);
    const [ openEditAlbum, setOpenEditAlbum ] = useState(false);
    const [ albumId, setAlbumId ] = useState(0);
    const [ openEditUser, setOpenEditUser ] = useState(false);
    const [ userId, setUserId ] = useState(0);
    const initDeleteData = {
        id: 0,
        model: "",
        isOpen: false,
        isLoading: false
    }
    const [ deleteData, setDeleteData ] = useState(initDeleteData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        get(`${appUrl}/api/performance`, (e) => setData(e.data));
    }

    const ActionButton = (icon, label) => (
        <div className='dashboard-action-button'>
            <div>{icon}</div>
            <div>{label}</div>
        </div>
    );

    const handleRedirect = (component, column, value) => {
        switch(component){
            case "videos":
                dispatch(videosFilter({[column] : value}));
                navigate("/videos/list");
                break;
            case "albums":
                dispatch(albumsFilter({[column] : value}));
                navigate("/albums/list");
                break;
            case "models":
                //dispatch(modelsFilter({[column] : value}));
                navigate("/models/list");
                break;
            case "channels":
                dispatch(channelsFilter({[column] : value}));
                navigate("/channels/list");
                break;
            case "categories":
                dispatch(contentCategoriesFilter({[column] : value}));
                navigate("/content-categories");
                break;
            case "tags":
                //dispatch(contentTagsFilter({[column] : value}));
                navigate("/tags/list");
                break;
            default:
                break;
        }
    }
    
    return (
        <div className='dashboard'>
            <Header title={"Home"} />
            <div className='dashboard-widget dashboard-widget-wide'>
                <AccountCircleOutlinedIcon />
                Logged as
                <div className='dashboard-bold'>{data.username}</div>
                <div className='dashboard-rc'>
                    <div>IP: {data.ip_address}</div>
                    <div>{getFormattedDate()}</div>
                </div>
            </div>
            <div className="dashboard-widget dashboard-widget-red">
                <div className="dashboard-widget-header">
                    <QueryStatsOutlinedIcon className="dashboard-widget-icon"/>
                    <div className='dashboard-widget-title'>Traffic overview</div>
                    <TubeSubmit variant={"outlined"} label="All statistics" />
                </div>
                <div className='dashboard-subheader'>
                    <div>Last 24 hours</div>
                    <div className="dashboard-tag-green">+ 0%</div>
                </div>
                <div className='dashboard-stats'>
                    <div>
                        <div>0</div>
                        <div>Total visitors</div>
                    </div>
                    <div>
                        <div>0</div>
                        <div>Video views</div>
                    </div>
                    <div>
                        <div>0</div>
                        <div>Photo views</div>
                    </div>
                    <div>
                        <div>0</div>
                        <div>Profile views</div>
                    </div>
                </div>
                <div className='line' />
                <div className='dashboard-subheader'>
                    <div>Last week</div>
                    <div className="dashboard-tag-green">+ 0%</div>
                </div>
                <div className='dashboard-stats'>
                    <div>
                        <div>0</div>
                        <div>Total visitors</div>
                    </div>
                    <div>
                        <div>0</div>
                        <div>Video views</div>
                    </div>
                    <div>
                        <div>0</div>
                        <div>Photo views</div>
                    </div>
                    <div>
                        <div>0</div>
                        <div>Profile views</div>
                    </div>
                </div>
            </div>
            <div className='dashboard-widget dashboard-widget-notifications'>
                <div className="dashboard-widget-header">
                    <NotificationsNoneOutlinedIcon className="dashboard-widget-icon"/>
                    <div className='dashboard-widget-title'>Notifications</div>
                    <TubeSubmit variant={"outlined"} label="All notifications" />
                </div>
            </div>
            <div className="dashboard-widget">
                <div className="dashboard-widget-header">
                    <StorageIcon className="dashboard-widget-icon"/>
                    <div className='dashboard-widget-title'>Server info</div>
                    <TubeSubmit variant={"outlined"} label="Go to settings" />
                </div>
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">PHP Version</div>
                    <div className="dashboard-widget-value">PHP {data.php_version}</div>
                </div>
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Local time</div>
                    <div className="dashboard-widget-value">{data.local_time}</div>
                </div>
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Currently encoding</div>
                    <div className="dashboard-widget-value">{data.queueCount} in queue</div>
                </div>
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">CPU usage</div>
                    <div className="dashboard-widget-value">{data.cpuUsage}</div>
                </div>
                {data.diskSpace.map((disk, i) => {
                    if(i !== 0){
                        return (
                            <div className="dashboard-widget-row">
                                <div className="dashboard-widget-value-name">Free HDD {i} space</div>
                                <div className="dashboard-widget-value">{disk.available}</div>
                            </div>
                        );
                    }
                })}
            </div>
            <div className='dashboard-widget'>
                <div className="dashboard-widget-header">
                    <WebOutlinedIcon className="dashboard-widget-icon"/>
                    <div className='dashboard-widget-title'>Site info</div>
                    <TubeSubmit variant={"outlined"} label="Go to settings" />
                </div>
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Current version</div>
                    <div className="dashboard-widget-value">{data.version}</div>
                </div>
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Domain</div>
                    <div className="dashboard-widget-value">{data.domain}</div>
                </div>
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Installed plugins</div>
                    <div className="dashboard-widget-value">0</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("videos", "status", 1)}>
                    <div className="dashboard-widget-value-name">Videos online</div>
                    <div className="dashboard-widget-value">{data.videos_active}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("videos", "status", 2)}>
                    <div className="dashboard-widget-value-name">Videos offline</div>
                    <div className="dashboard-widget-value">{data.videos_inactive}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("albums", "status", 1)}>
                    <div className="dashboard-widget-value-name">Albums online</div>
                    <div className="dashboard-widget-value">{data.albums_active}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("albums", "status", 2)}>
                    <div className="dashboard-widget-value-name">Albums offline</div>
                    <div className="dashboard-widget-value">{data.albums_inactive}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("models", "status", 1)}>
                    <div className="dashboard-widget-value-name">Models online</div>
                    <div className="dashboard-widget-value">{data.models_active}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("models", "status", 2)}>
                    <div className="dashboard-widget-value-name">Models offline</div>
                    <div className="dashboard-widget-value">{data.models_inactive}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("channels", "status", 1)}>
                    <div className="dashboard-widget-value-name">Channels online</div>
                    <div className="dashboard-widget-value">{data.channels_active}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("channels", "status", 2)}>
                    <div className="dashboard-widget-value-name">Channels offline</div>
                    <div className="dashboard-widget-value">{data.channels_inactive}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("categories", "status", 1)}>
                    <div className="dashboard-widget-value-name">Categories online</div>
                    <div className="dashboard-widget-value">{data.categories_active}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("categories", "status", 2)}>
                    <div className="dashboard-widget-value-name">Categories offline</div>
                    <div className="dashboard-widget-value">{data.categories_inactive}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("tags", "status", 1)}>
                    <div className="dashboard-widget-value-name">Tags online</div>
                    <div className="dashboard-widget-value">{data.tags_active}</div>
                </div>
                <div className="dashboard-widget-row dashboard-widget-row-link" onClick={() => handleRedirect("tags", "status", 2)}>
                    <div className="dashboard-widget-value-name">Tags offline</div>
                    <div className="dashboard-widget-value">{data.tags_inactive}</div>
                </div>
            </div>
            <div className='dashboard-list'>
                <div className='dashboard-widget-header'>
                    <div className='dashboard-widget-title'>Newest videos</div>
                    <div className='dashboard-rc'>
                        <div>In 24 hours</div>
                        <div className='dashboard-tag-orange'>{data.videos_24h}</div>
                    </div>
                </div>    
                <div className='line' />
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Active videos</div>
                    <div className="dashboard-widget-value">{data.videos_active}</div>
                </div>
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Inactive videos</div>
                    <div className="dashboard-widget-value">{data.videos_inactive}</div>
                </div>
                <div className='line' />
                {data.videos && data.videos.map(video => (
                    <div className='dashboard-video'>
                        <div className='dashboard-video-row'>
                            <Box key={video.id} className="dashboard-video-thumbnail" sx={{backgroundImage: `url(${appUrl}/videos/thumbs/${video.slug}.jpg)`}}>
                                <video autoPlay loop src={`${appUrl}/videos/thumbs/preview-${video.slug}.mp4`} width={150} height={85} style={{display:"none"}}/>
                                <PlayArrowOutlined />
                            </Box>
                            <div>
                                <div className='dashboard-video-title'>{video.name}</div>
                                {video.user ? `${video.user.display_name}` : "Admin"}
                                <div className='dashboard-video-created'>Created: {timestampToDate(video.created_at)}</div>
                            </div>
                            <div>
                                <IconButton aria-label="edit" onClick={() => {setOpenEditVideo(true); setVideoId(video.id)}}>
                                    <ModeOutlined sx={{
                                        fontSize: 20,
                                        color: "#004159"
                                    }}/>
                                </IconButton>
                                <IconButton aria-label="delete" onClick={() => {setDeleteData({
                                    id: video.id,
                                    isOpen: true,
                                    model: "video",
                                    isLoading: false
                                })}}>
                                    <DeleteOutlineOutlined sx={{
                                        fontSize: 20,
                                        color: "#004159"
                                    }}/>
                                </IconButton>
                            </div>
                        </div>
                        <div className='line' />
                    </div>
                ))}
            </div>
            <div className='dashboard-list'>
                <div className='dashboard-widget-header'>
                    <div className='dashboard-widget-title'>Newest albums</div>
                    <div className='dashboard-rc'>
                        <div>In 24 hours</div>
                        <div className='dashboard-tag-orange'>{data.albums_24h}</div>
                    </div>
                </div>    
                <div className='line' />
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Active albums</div>
                    <div className="dashboard-widget-value">{data.albums_active}</div>
                </div>
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Inactive albums</div>
                    <div className="dashboard-widget-value">{data.albums_inactive}</div>
                </div>
                <div className='line' />
                {data.albums && data.albums.map(album => (
                    <div className='dashboard-video'>
                        <div className='dashboard-video-row'>
                            <Box key={album.id} className="dashboard-video-thumbnail" sx={{backgroundImage: `url(${appUrl}/photos/${album.featured && album.featured !== " " ? album.featured : (album.photos && album.photos[0]) ? album.photos[0].slug : ""}.jpg)`}}/>
                            <div>
                                <div className='dashboard-video-title'>{album.name}</div>
                                {album.user ? `${album.user.display_name}` : "Admin"}
                                <div className='dashboard-video-created'>Created: {timestampToDate(album.created_at)}</div>
                            </div>
                            <div>
                                <IconButton aria-label="edit" onClick={() => {setOpenEditAlbum(true); setAlbumId(album.id)}}>
                                    <ModeOutlined sx={{
                                        fontSize: 20,
                                        color: "#004159"
                                    }}/>
                                </IconButton>
                                <IconButton aria-label="delete" onClick={() => {setDeleteData({
                                    id: album.id,
                                    isOpen: true,
                                    model: "album",
                                    isLoading: false
                                })}}>
                                    <DeleteOutlineOutlined sx={{
                                        fontSize: 20,
                                        color: "#004159"
                                    }}/>
                                </IconButton>
                            </div>
                        </div>
                        <div className='line' />
                    </div>
                ))}
            </div>
            <div className='dashboard-list'>
                <div className='dashboard-widget-header'>
                    <div className='dashboard-widget-title'>Newest users</div>
                    <div className='dashboard-rc'>
                        <div>In 24 hours</div>
                        <div className='dashboard-tag-orange'>{data.users_24h}</div>
                    </div>
                </div>    
                <div className='line' />
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Total users</div>
                    <div className="dashboard-widget-value">{data.users_total}</div>
                </div>
                <div className="dashboard-widget-row">
                    <div className="dashboard-widget-value-name">Recently registered (7 days)</div>
                    <div className="dashboard-widget-value">{data.users_7d}</div>
                </div>
                <div className='line' />
                {data.users && data.users.map(user => (
                    <div className='dashboard-video'>
                        <div className='dashboard-video-row'>
                            <Avatar
                                alt={user.display_name}
                                className="user-avatar"
                                src={`${appUrl}/avatars/${user.avatar}`}
                                sx={{ width: 40 }}
                            >
                                {user.display_name.match(/\b(\w)/g).join('')}
                            </Avatar>
                            <div>
                                <div className='dashboard-video-title'>{user.display_name}</div>
                                <div className='dashboard-video-created'>Registered: {timestampToDate(user.created_at)}</div>
                            </div>
                            <div>
                                <IconButton aria-label="edit" onClick={() => {setOpenEditUser(true); setUserId(user.id)}}>
                                    <ModeOutlined sx={{
                                        fontSize: 20,
                                        color: "#004159"
                                    }}/>
                                </IconButton>
                                <IconButton aria-label="delete" onClick={() => {setDeleteData({
                                    id: user.id,
                                    isOpen: true,
                                    model: "user",
                                    isLoading: false
                                })}}>
                                    <DeleteOutlineOutlined sx={{
                                        fontSize: 20,
                                        color: "#004159"
                                    }}/>
                                </IconButton>
                            </div>
                        </div>
                        <div className='line' />
                    </div>
                ))}
            </div>
            <div className='dashboard-actions'>
                <div className='dashboard-widget-header'>Quick actions</div>
                <div>
                    {ActionButton("test", "Add video")}
                </div>
            </div>
            <DialogComponent
                open={openEditVideo}
                onClose={() => { setOpenEditVideo(false) }}
                content={<Video id={videoId} closeFunc={() => {getData(); setOpenEditVideo(false)}}/>}
                buttons={[
                    {
                        variant: "outlined",
                        label: "Delete",
                        color: "secondary",
                        onClick: () => setDeleteData({
                            id: videoId,
                            isOpen: true,
                            model: "video",
                            isLoading: false
                        }),
                    },
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "secondary",
                        onClick: () => { setOpenEditVideo(false) },
                    }
                ]}
                form={true}
            />
            <DialogComponent
                open={openEditAlbum}
                onClose={() => { setOpenEditAlbum(false) }}
                content={<Album id={albumId} closeFunc={() => {getData(); setOpenEditAlbum(false)}}/>}
                buttons={[
                    {
                        variant: "outlined",
                        label: "Delete",
                        color: "secondary",
                        onClick: () => setDeleteData({
                            id: albumId,
                            isOpen: true,
                            model: "album",
                            isLoading: false
                        }),
                    },
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "secondary",
                        onClick: () => { setOpenEditAlbum(false) },
                    }
                ]}
                form={true}
            />
            <DialogComponent
                open={openEditUser}
                onClose={() => { setOpenEditUser(false) }}
                content={<User id={userId} closeFunc={() => {getData(); setOpenEditUser(false)}}/>}
                buttons={[
                    {
                        variant: "outlined",
                        label: "Delete",
                        color: "secondary",
                        onClick: () => setDeleteData({
                            id: userId,
                            isOpen: true,
                            model: "user",
                            isLoading: false
                        }),
                    },
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "secondary",
                        onClick: () => { setOpenEditUser(false) },
                    }
                ]}
                form={true}
            />
            <DialogComponent
                open={deleteData.isOpen}
                onClose={() => {setDeleteData({initDeleteData})}}
                content={"Are you sure you want to delete selected item/s?"}
                isLoading={deleteData.isLoading}
                buttons={[
                    {
                        variant: "contained",
                        label: "Delete",
                        color: "error",
                        onClick: () => post(
                            `${appUrl}/api/${deleteData.model}-delete`,
                            { items: deleteData.id },
                            () => {
                                setDeleteData(initDeleteData)
                                setOpenEditVideo(false);
                                getData();
                            },
                            () => setDeleteData({...deleteData, isLoading: true})
                        )
                    },
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "primary",
                        onClick: () => setDeleteData(initDeleteData),
                    }
                ]}
            />
        </div>
    );
}