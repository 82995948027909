import TextInput from "../form/TextInput";
import { edit } from "../../slices/settingsSlice";
import { edit as editMainMenu } from "../../slices/settingsMainMenuSlice";
import { edit as editSettings } from "../../slices/settingsSlice";
import Title from "../navigation/Title";
import TextArea from "../form/TextArea";
import TubeUpload from "../form/Upload";
import { appUrl, get } from "../../utils/fetch";
import TubeDragAndDrop from "../navigation/TubeDragAndDrop";
import SelectComponent from "../form/Select";
import ButtonSwitch from "../form/ButtonSwitch";
import { IconButton } from "@mui/material";
import { DeleteOutlineOutlined, ModeOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TubeSubmit from "../form/TubeSubmit";
import DialogComponent from "../dialog/Dialog";
import { SocialIcon } from 'react-social-icons'
import ColorPickerComponent from "../form/ColorPicker";

export default function SettingsAppearance(props){
    const { isLoading } = props;
    const dispatch = useDispatch();

    const mainMenu = useSelector(state => state.settings?.mainMenu);
    const backLinks = useSelector(state => state.settings?.backLinks);
    const socialMedia = useSelector(state => state.settings?.socialMedia);

    const [ mainMenuData, setMainMenuData ] = useState([]);
    const mainMenuItemInitState = {isOpen: false, id: 0, name: "", type: 1, page: "", target: "_self", status: 1};
    const [ mainMenuItem, setMainMenuItem ] = useState(mainMenuItemInitState);
    const [ socialMediaState, setSocialMediaState ] = useState({
        facebook: "",
        instagram: "",
        reddit: "",
        x: "",
        pinterest: "",
        tiktok: "",
        whatsapp: ""
    });
    const backLinksItemInitState = {isOpen: false, id: 0, name: "", link: "", rel: 0, target: "_self", status: 1};
    const [ backLinksItem, setBackLinksItem ] = useState(backLinksItemInitState);
    const themeInit = {
        themeColorPrimary: "#123",
        themeColorPrimaryLight: "#414e5c",
        themeColorPrimaryDark: "#012",
        themeColorButton: "#ff6400",
        themeColorLink: "#9ba3aa",
        themeColorWhite: "#fff",
        themeColorBlack: "#012",
    };

    const addMainMenuItem = () => {
        let mainMenuObject = JSON.parse(mainMenu);
        let itemId;
        if(mainMenuItem?.id > 0){
            itemId = mainMenuItem.id;
        }
        else {
            if(Object.keys(mainMenuObject).length > 0){
                const biggestId = Math.max(...Object.keys(mainMenuObject).map(Number));
                itemId = biggestId + 1;
            }
            else {
                itemId = 1;
            }
        }
        mainMenuObject = {
            ...mainMenuObject,
            [parseInt(itemId)] : {
                name: mainMenuItem.name,
                type: mainMenuItem.type,
                page: mainMenuItem.page,
                target: mainMenuItem.target,
                status: mainMenuItem.status
            }
        }
        dispatch(editSettings({mainMenu: JSON.stringify(mainMenuObject)}));
        setMainMenuItem(mainMenuItemInitState);
    }

    const addBackLinksItem = () => {
        let backLinksObject = JSON.parse(backLinks);
        let itemId;
        if(backLinksItem?.id > 0){
            itemId = backLinksItem.id;
        }
        else {
            if(Object.keys(backLinksObject).length > 0){
                const biggestId = Math.max(...Object.keys(backLinksObject).map(Number));
                itemId = biggestId + 1;
            }
            else {
                itemId = 1;
            }
        }
        backLinksObject = {
            ...backLinksObject,
            [parseInt(itemId)] : {
                name: backLinksItem.name,
                link: backLinksItem.link,
                rel: backLinksItem.rel,
                target: backLinksItem.target,
                status: backLinksItem.status
            }
        }
        dispatch(editSettings({backLinks: JSON.stringify(backLinksObject)}));
        setBackLinksItem(backLinksItemInitState);
    }

    const updateMainMenuItem = (newValues) => {
        setMainMenuItem((prevState) => ({ ...prevState, ...newValues }));
    };

    const updateBackLinksItem = (newValues) => {
        setBackLinksItem((prevState) => ({ ...prevState, ...newValues }));
    };

    const updateSocialMediaState = (key, value) => {
        setSocialMediaState((prevState) => ({ ...prevState, [key]: value }));
        let newObject = {};
        const oldObject = JSON.parse(socialMedia);
        Object.keys(oldObject).forEach(objectKey => {
            let item = oldObject[objectKey];
            if(item.name === key){
                item.url = value;
            }
            if(objectKey !== "url"){
                newObject = {
                    ...newObject,
                    [objectKey]: item
                }
            }
        })
        dispatch(editSettings({socialMedia: JSON.stringify(newObject)}));
    };

    const deleteMainMenuItem = (id) => {
        let mainMenuObject = JSON.parse(mainMenu);
        delete mainMenuObject[id];
        dispatch(editSettings({mainMenu: JSON.stringify(mainMenuObject)}));
    }

    const deleteBackLinksItem = (id) => {
        let backLinksObject = JSON.parse(backLinks);
        delete backLinksObject[id];
        dispatch(editSettings({backLinks: JSON.stringify(backLinksObject)}));
    }

    useEffect(() => {
        get(
            `${appUrl}/api/content`,
            (result) => setMainMenuData(
                result.data.data.reduce((acc, item) => {
                    acc[item.id] = item.title;
                    return acc;
                }, {})
            )
        );
    }, []);

    useEffect(() => {
        const socialMediaObject = socialMedia ? JSON.parse(socialMedia) : {};
        let newState = {};
        Object.keys(socialMediaObject).forEach(key => {
            newState = {
                ...newState,
                [socialMediaObject[key].name]: socialMediaObject[key].url
            }
        });
        if(newState !== socialMediaState) {
            setSocialMediaState(newState);
        }
    }, [socialMedia]);

    return (
        <div className="settings-appearance">
            <Title
                title="Appearance"
                fontSize={22}
                sx={{
                    marginTop: "20px"
                }}
            />
            <div className="settings-card">
                <Title
                    title="Site info"
                    fontSize={20}
                    sx={{
                        marginBottom: "10px"
                    }}
                />
                <TextInput
                    label="Site title"
                    stateName="settings"
                    valueName="siteTitle"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                />
                <TextInput
                    label="Site tagline"
                    stateName="settings"
                    valueName="siteTagline"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                />
                <TextArea
                    label="Descrition"
                    stateName="settings"
                    valueName="siteDescription"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                />
            </div>
            <div className="settings-card settings-card-row">
                <Title
                    title="Site identity"
                    fontSize={20}
                    sx={{
                        width: "100%"
                    }}
                />
                <TubeUpload
                    label={"Header logo"}
                    stateName={"settings"}
                    valueName={"file_header_logo"}
                    dispatchFunc={edit}
                    disabled={isLoading}
                    buttonLabel={"Upload"}
                    noInput
                    thumbnail={{
                        w: "100px",
                        h: "100px"
                    }}
                    fileUrl={`${appUrl}/assets/file_header_logo.png`}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                    disableDelete
                    accept={"image/png"}
                />
                <TubeUpload
                    label={"Footer logo"}
                    stateName={"settings"}
                    valueName={"file_footer_logo"}
                    dispatchFunc={edit}
                    disabled={isLoading}
                    buttonLabel={"Upload"}
                    noInput
                    thumbnail={{
                        w: "100px",
                        h: "100px"
                    }}
                    fileUrl={`${appUrl}/assets/file_footer_logo.png`}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                    disableDelete
                    accept={"image/png"}
                />
                <TubeUpload
                    label={"Favicon"}
                    stateName={"settings"}
                    valueName={"file_favicon"}
                    dispatchFunc={edit}
                    disabled={isLoading}
                    buttonLabel={"Upload"}
                    noInput
                    thumbnail={{
                        w: "100px",
                        h: "100px"
                    }}
                    fileUrl={`${appUrl}/assets/file_favicon.png`}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                    disableDelete
                    accept={"image/png"}
                />
                <TubeUpload
                    label={"Webclip"}
                    stateName={"settings"}
                    valueName={"file_webclip"}
                    dispatchFunc={edit}
                    disabled={isLoading}
                    buttonLabel={"Upload"}
                    noInput
                    thumbnail={{
                        w: "100px",
                        h: "100px"
                    }}
                    fileUrl={`${appUrl}/assets/file_webclip.png`}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                    disableDelete
                    accept={"image/png"}
                />
            </div>
            <div className="settings-card settings-card-white">
                <div className="settings-title-wrapper">
                    <Title
                        title="Main menu"
                        fontSize={20}
                    />
                    <TubeSubmit
                        label={"New item"}
                        icon={"add"}
                        onClick={() => updateMainMenuItem({isOpen: true})}
                    />
                </div>
                <TubeDragAndDrop
                    droppableId="settingsMainMenu"
                    itemsObject={mainMenu ? JSON.parse(mainMenu) : {}}
                    setItemsObject={(result) => {
                        dispatch(editSettings({mainMenu: JSON.stringify(result)}));
                    }}
                    items={mainMenu ? Object.keys(JSON.parse(mainMenu))?.map(key => {
                        const item = JSON.parse(mainMenu)?.[key];
                        return {
                            id: key,
                            content: (
                                <React.Fragment key={key}>
                                    <div className={"bold"}>{item.name }</div>
                                    <div>{parseInt(item.type) === 1 ? "Internal" : "External" }</div>
                                    <div>
                                        {
                                            item.page === "a" ? "Videos" : 
                                            item.page === "b" ? "Galleries" : 
                                            item.page === "c" ? "Channels" : 
                                            item.page === "d" ? "Models" : 
                                            item.page === "e" ? "Categories" : 
                                            item.page === "f" ? "Tags" : 
                                            mainMenuData[parseInt(item.page)] || 
                                            item.page || 
                                            "Undefined page"
                                        }
                                    </div>
                                    <div>{item.target === "_self" ? "Same tab" : "New tab" }</div>
                                    <div>{parseInt(item.status) === 1 ? (<div className="tube-status-online">Online</div>) : (<div className="tube-status-offline">Offline</div>)}</div>
                                    <div className={"settings-button-stack"}>
                                        <IconButton aria-label="edit" onClick={() => {
                                            updateMainMenuItem({
                                                isOpen: true,
                                                id: parseInt(key),
                                                name: item.name,
                                                type: item.type,
                                                page: item.page,
                                                target: item.target,
                                                status: item.status
                                            })
                                        }}>
                                            <ModeOutlined sx={{
                                                fontSize: 20,
                                                color: "#004159"
                                            }}/>
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={() => deleteMainMenuItem(key)}>
                                            <DeleteOutlineOutlined sx={{
                                                fontSize: 20,
                                                color: "#004159"
                                            }}/>
                                        </IconButton>
                                    </div>
                                </React.Fragment>
                            )
                        };
                    }) : []}
                />
            </div>
            <div className="settings-card settings-card-white">
                <Title
                    title="Social"
                    fontSize={20}
                />
                <TubeDragAndDrop
                    droppableId="settingsSocialMedia"
                    itemsObject={socialMedia ? JSON.parse(socialMedia) : {}}
                    setItemsObject={(result) => {
                        dispatch(editSettings({socialMedia: JSON.stringify(result)}));
                    }}
                    items={socialMedia ? Object.keys(JSON.parse(socialMedia))?.map(key => {
                        const item = JSON.parse(socialMedia)?.[key];
                        return {
                            id: key,
                            content: (
                                <React.Fragment key={key}>
                                    <div style={{width: 80}}>
                                        <SocialIcon network={item.name } url="/" bgColor="rgb(170, 187, 204)" />
                                    </div>
                                    <div className={"bold capitalize"} style={{width: 150}}>{item.name }</div>
                                    <div>
                                        <TextInput
                                            noRedux
                                            noReduxValue={socialMediaState[item.name]}
                                            noReduxOnChange={(e) => updateSocialMediaState(item.name, e)}
                                            label={"URL"}
                                        />
                                    </div>
                                </React.Fragment>
                            )
                        };
                    }) : []}
                />
            </div>
            <div className="settings-card settings-card-white">
                <div className="settings-title-wrapper">
                    <Title
                        title="Backlinks"
                        fontSize={20}
                    />
                    <TubeSubmit
                        label={"New backlink"}
                        icon={"add"}
                        onClick={() => updateBackLinksItem({isOpen: true})}
                    />
                </div>
                <TubeDragAndDrop
                    droppableId="settingsBackLinks"
                    itemsObject={backLinks ? JSON.parse(backLinks) : {}}
                    setItemsObject={(result) => {
                        dispatch(editSettings({backLinks: JSON.stringify(result)}));
                    }}
                    items={backLinks ? Object.keys(JSON.parse(backLinks))?.map(key => {
                        const item = JSON.parse(backLinks)?.[key];
                        return {
                            id: key,
                            content: (
                                <React.Fragment key={key}>
                                    <div className={"bold"}>{item.name }</div>
                                    <div>{item.link }</div>
                                    <div>Attribute: {parseInt(item.rel) === 0 ? "-" : item.rel}</div>
                                    <div>{item.target === "_self" ? "Same tab" : "New tab" }</div>
                                    <div>
                                        {
                                            parseInt(item.status) === 1 ? 
                                                (<div className="tube-status-online">Online</div>) : 
                                                (<div className="tube-status-offline">Offline</div>)
                                        }
                                    </div>
                                    <div className={"settings-button-stack"}>
                                        <IconButton aria-label="edit" onClick={() => {
                                            updateBackLinksItem({
                                                isOpen: true,
                                                id: parseInt(key),
                                                name: item.name,
                                                link: item.link,
                                                rel: item.rel,
                                                target: item.target,
                                                status: item.status
                                            })
                                        }}>
                                            <ModeOutlined sx={{
                                                fontSize: 20,
                                                color: "#004159"
                                            }}/>
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => deleteBackLinksItem(key)}
                                        >
                                            <DeleteOutlineOutlined sx={{
                                                fontSize: 20,
                                                color: "#004159"
                                            }}/>
                                        </IconButton>
                                    </div>
                                </React.Fragment>
                            )
                        };
                    }) : []}
                />
            </div>
            <div className="settings-card">
                <Title
                    title="Cookies consents"
                    fontSize={20}
                    sx={{
                        marginBottom: "10px"
                    }}
                />
                <TextArea
                    label="Visible text"
                    stateName="settings"
                    valueName="cookiesNotificationContent"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                    children={(
                        <ButtonSwitch
                            stateName="settings"
                            valueName="cookiesNotification"
                            dispatchFunc={edit}
                            label={""}
                            data={[
                                { id: "true", label: "Enabled", color: "green" },
                                { id: "false", label: "Disabled", color: "red" }
                            ]}
                            sx={{
                                marginTop: "10px"
                            }}
                        />    
                    )}
                />
            </div>
            <div className="settings-card">
                <Title
                    title="18+ Alert"
                    fontSize={20}
                    sx={{
                        marginBottom: "10px"
                    }}
                />
                <TextArea
                    label="Visible text"
                    stateName="settings"
                    valueName="adultWarningContent"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                    children={(
                        <ButtonSwitch
                            stateName="settings"
                            valueName="adultWarning"
                            dispatchFunc={edit}
                            label={""}
                            data={[
                                { id: "true", label: "Enabled", color: "green" },
                                { id: "false", label: "Disabled", color: "red" }
                            ]}
                            sx={{
                                marginTop: "10px"
                            }}
                        />    
                    )}
                />
            </div>
            <div className="settings-card">
                <Title
                    title="Out of Service"
                    fontSize={20}
                    sx={{
                        marginBottom: "10px"
                    }}
                />
                <TextArea
                    label="Visible text"
                    stateName="settings"
                    valueName="disableWebsiteContent"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                    children={(
                        <ButtonSwitch
                            stateName="settings"
                            valueName="disableWebsite"
                            dispatchFunc={edit}
                            label={""}
                            data={[
                                { id: "true", label: "Enabled", color: "green" },
                                { id: "false", label: "Disabled", color: "red" }
                            ]}
                            sx={{
                                marginTop: "10px"
                            }}
                        />    
                    )}
                />
            </div>
            <div className="settings-card settings-card-row">
                <Title
                    title="Themes"
                    fontSize={20}
                    sx={{
                        width: "100%"
                    }}
                />
                <div className="settings-sub-card" style={{width: "calc((100% - 30px)/3)"}}>
                    <div className="add-img"/>
                    <Title
                        title="Default theme"
                        fontSize={20}
                        variant={"h2"}
                        sx={{
                            padding: "0 30px",
                            width: "100%"
                        }}
                    />
                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <div
                    className="settings-sub-card settings-sub-card-design"
                    style={{width: "calc((100% - 30px)/1.5)"}}
                >
                    <div className="settings-title-wrapper">
                        <Title
                            title="Theme"
                            variant={"h2"}
                            fontSize={20}
                        />
                        <TubeSubmit
                            label={"Reset default"}
                            onClick={() => dispatch(editSettings({...themeInit}))}
                        />
                    </div>
                    <ColorPickerComponent
                        label="Primary color"
                        stateName="settings"
                        valueName="themeColorPrimary"
                        dispatchFunc={edit}
                        disabled={isLoading}
                    />
                    <ColorPickerComponent
                        label="Primary light color"
                        stateName="settings"
                        valueName="themeColorPrimaryLight"
                        dispatchFunc={edit}
                        disabled={isLoading}
                    />
                    <ColorPickerComponent
                        label="Primary dark color"
                        stateName="settings"
                        valueName="themeColorPrimaryDark"
                        dispatchFunc={edit}
                        disabled={isLoading}
                    />
                    <ColorPickerComponent
                        label="Button color"
                        stateName="settings"
                        valueName="themeColorButton"
                        dispatchFunc={edit}
                        disabled={isLoading}
                    />
                    <ColorPickerComponent
                        label="Link color"
                        stateName="settings"
                        valueName="themeColorLink"
                        dispatchFunc={edit}
                        disabled={isLoading}
                    />
                    <ColorPickerComponent
                        label="White"
                        stateName="settings"
                        valueName="themeColorWhite"
                        dispatchFunc={edit}
                        disabled={isLoading}
                    />
                    <ColorPickerComponent
                        label="Black"
                        stateName="settings"
                        valueName="themeColorBlack"
                        dispatchFunc={edit}
                        disabled={isLoading}
                    />
                </div>
            </div>
            <DialogComponent
                onClose={() => updateMainMenuItem(mainMenuItemInitState)}
                open={mainMenuItem.isOpen}
                className={"settings-form-dialog"}
                title={"Main menu item"}
                content={(
                    <>
                        <TextInput
                            noRedux
                            noReduxValue={mainMenuItem.name}
                            noReduxOnChange={(e) => updateMainMenuItem({name: e})}
                            label={"Title"}
                        />
                        <SelectComponent
                            noRedux
                            noReduxValue={mainMenuItem.type}
                            noReduxOnChange={(e) => updateMainMenuItem({type: e})}
                            label={"Type"}
                            data={{
                                1: "Internal",
                                2: "External"
                            }}
                        />
                        {parseInt(mainMenuItem?.type) === 1 ? (
                            <SelectComponent
                                noRedux
                                noReduxValue={mainMenuItem.page}
                                noReduxOnChange={(e) => updateMainMenuItem({page: e})}
                                label={"Page"}
                                data={{
                                    "a": "Videos",
                                    "b": "Galleries",
                                    "c": "Channels",
                                    "d": "Models",
                                    "e": "Categories",
                                    "f": "Tags",
                                    ...mainMenuData
                                }}
                            />    
                        ) : (
                            <TextInput
                                noRedux
                                noReduxValue={mainMenuItem.page}
                                noReduxOnChange={(e) => updateMainMenuItem({page: e})}
                                label={"URL"}
                            />
                        )}
                        <SelectComponent
                            noRedux
                            noReduxValue={mainMenuItem.target}
                            noReduxOnChange={(e) => updateMainMenuItem({target: e})}
                            label={"Target"}
                            data={{
                                "_self": "Same tab",
                                "_blank": "New tab"
                            }}
                        />
                        <ButtonSwitch
                            noRedux
                            noReduxValue={mainMenuItem.status}
                            noReduxOnChange={(e) => updateMainMenuItem({status: e})}
                            label={"Status"}
                            data={[
                                { id: 1, label: "Online", color: "green" },
                                { id: 2, label: "Offline", color: "red" }
                            ]}
                        />
                    </>
                )}
                buttons={[
                {
                    variant: "contained",
                    label: mainMenuItem?.id > 0 ? "Edit" : "Add",
                    onClick: addMainMenuItem,
                },
                {
                    variant: "outlined",
                    label: "Cancel",
                    color: "secondary",
                    onClick: () => updateMainMenuItem(mainMenuItemInitState),
                }
                ]}  
            />
            <DialogComponent
                onClose={() => updateBackLinksItem(backLinksItemInitState)}
                open={backLinksItem.isOpen}
                className={"settings-form-dialog"}
                title={"Backlinks item"}
                content={(
                    <>
                        <TextInput
                            noRedux
                            noReduxValue={backLinksItem.name}
                            noReduxOnChange={(e) => updateBackLinksItem({name: e})}
                            label={"Title"}
                        />
                        <TextInput
                            noRedux
                            noReduxValue={backLinksItem.link}
                            noReduxOnChange={(e) => updateBackLinksItem({link: e})}
                            label={"Link"}
                        />
                        <SelectComponent
                            noRedux
                            noReduxValue={backLinksItem.rel}
                            noReduxOnChange={(e) => updateBackLinksItem({rel: e})}
                            label={"Attribute"}
                            data={{
                                0: "No attriubte",
                                "ugc": "UGC",
                                "sponsored": "Sponsored",
                                "nofollow": "Nofollow"
                            }}
                        />
                        <SelectComponent
                            noRedux
                            noReduxValue={backLinksItem.target}
                            noReduxOnChange={(e) => updateBackLinksItem({target: e})}
                            label={"Target"}
                            data={{
                                "_self": "Same tab",
                                "_blank": "New tab"
                            }}
                        />
                        <ButtonSwitch
                            noRedux
                            noReduxValue={backLinksItem.status}
                            noReduxOnChange={(e) => updateBackLinksItem({status: e})}
                            label={"Status"}
                            data={[
                                { id: 1, label: "Online", color: "green" },
                                { id: 2, label: "Offline", color: "red" }
                            ]}
                        />
                    </>
                )}
                buttons={[
                {
                    variant: "contained",
                    label: backLinksItem?.id > 0 ? "Edit" : "Add",
                    onClick: addBackLinksItem,
                },
                {
                    variant: "outlined",
                    label: "Cancel",
                    color: "secondary",
                    onClick: () => updateBackLinksItem(backLinksItemInitState),
                }
                ]}  
            />
        </div>
    );
}