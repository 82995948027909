import DataTable from "../dataTable/DataTable";
import { appUrl, get } from "../../utils/fetch";
import { edit } from '../../slices/historyFilterSlice';
import "./HistoryList.css"
import DialogComponent from "../dialog/Dialog";
import { useState } from "react";

function HistoryList(props) {
    const { id } = props;
    const [ message, setMessage ] = useState({
        isOpen: false,
        message: "",
        isLoading: false
    });

    const openMessage = (id) => {
        get(
            `${appUrl}/api/grabber-history-message/${id}`,
            (result) => {
                setMessage({
                    message: (result && result.data.message) ? result.data.message : "",
                    isOpen: true,
                    isLoading: false
                })
            },
            () => setMessage({
                message: "",
                isOpen: true,
                isLoading: true
            })
        )
    }

    return (
        <div>
            <DataTable 
                title="History"
                className="history-list-table"
                dataUrl={`${appUrl}/api/grabber-history/${id}`}
                url={"/videos/import/history"}
                subpageUrl={"/videos/import/history"}
                filterStateName="historyFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/grabber-history/${id}`}
                disableSelect
                disableBulk
                extraUtils={[
                    { 
                        label: "Message",
                        icon: "alert",
                        conditionProp: "message",
                        onClick: (row) => openMessage(row.id)
                    }
                ]}
                header={{
                    id: {
                        label: "ID",
                        field: "text",
                        noSort: true
                    },
                    url: {
                        label: "URL",
                        field: "text",
                        noSort: true
                    },
                    itemStatus: {
                        label: "Status",
                        field: "html",
                        noSort: true
                    },
                    started: {
                        label: "Started",
                        field: "text",
                        noSort: true
                    },
                    finished: {
                        label: "Finished",
                        field: "text",
                        noSort: true
                    },
                    message: {
                        label: "",
                        field: "hidden",
                        noSort: true
                    }
                }}
            />
            <DialogComponent
                content={(
                    <pre>
                        {message.message}
                    </pre>
                )}
                isLoading={message.isLoading}
                open={message.isOpen}
                className={"history-dialog"}
                onClose={() => setMessage({isOpen: false, message: ""})}
                buttons={[
                    {
                        variant: "outlined",
                        label: "Close",
                        color: "secondary",
                        onClick: () => setMessage({isOpen: false, message: ""}),
                    }
                ]}
            />
        </div>
    );
}

export default HistoryList;