import { appUrl, get, del, post } from "../../utils/fetch";
import ContentCategory from './ContentCategory';
import './ContentCategories.css';
import Title from "../navigation/Title";
import TubeSubmit from "../form/TubeSubmit";
import { useEffect, useState } from "react";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { IconButton } from "@mui/material";
import { DeleteOutlineOutlined, ModeOutlined } from "@mui/icons-material";
import DialogComponent from "../dialog/Dialog";
import TubeCheckbox from "../form/TubeCheckbox";
import { useSelector } from "react-redux";

function ContentCategories() {

    const [ categories, setCategories ] = useState([]);
    const [ categoryId, setCategoryId ] = useState(0);
    const [ isOpenEdit, setIsOpenEdit ] = useState(false);
    const [ isOpenDelete, setIsOpenDelete ] = useState(false);
    const [ deleteId, setDeleteId ] = useState(0);
    const [ selected, setSelected ] = useState([]);
    const [ isLoadingDelete, setIsLoadingDelete ] = useState(false);
    const [ isOpenBulkDelete, setIsOpenBulkDelete ] = useState(false);
    const filter = useSelector((state) => state.contentCategoriesFilter);

    const getCategories = async () => {
        let filterQuery = "";
        if(filter){
            filterQuery = "?" + new URLSearchParams(filter).toString();
        }
        get(`${appUrl}/api/content-categories${filterQuery}`, (data) => setCategories(data.data));
    }

    const deleteCategories = async () => {
        post(
            `${appUrl}/api/content-categories-delete`, 
            {
                items: selected.join(",")
            },
            () => {
                getCategories();
                setIsLoadingDelete(false);
                setIsOpenBulkDelete(false);
                setSelected([]);
            },
            () => setIsLoadingDelete(true)
        );
    }

    useEffect(() => {
        getCategories(filter);
    }, [filter])

    const handleSelect = (id) => {
        let selectedNew = [...selected];
        const index = selectedNew.indexOf(id);
        if (index > -1) {
            selectedNew.splice(index, 1);
        } else {
            selectedNew.push(id);
        }
        console.log(selectedNew);
        setSelected(selectedNew);    
    }

    return (
        <div>
            <Title fontSize={24} title="Categories" />
            <TubeSubmit onClick={() => {setIsOpenEdit(true); setCategoryId("new")}} label="Add category" variant="contained" icon="add" sx={{marginTop: "5px", marginBottom: "30px"}}/>
            <TubeSubmit onClick={() => setIsOpenBulkDelete(true)} label="Delete selected" variant="outlined" icon="delete" sx={{marginLeft: "5px", marginBottom: "25px"}}/>
            {categories ? categories.map(category => (
                <div key={category.id} className="category-line" style={{paddingLeft: category.parent_id > 0 ? 40 : 0}}>
                    <div>
                        <TubeCheckbox
                            checked={selected.includes(category.id)}
                            onChange={() => handleSelect(category.id)}
                        /> 
                    </div>
                    <div><DragHandleIcon /></div>
                    <div>
                        <div className="name">{category.name}</div>
                        <div className="created_at">Created: {category.created_at}</div>
                    </div>
                    <div>
                        <IconButton aria-label="edit" onClick={() => {setIsOpenEdit(true); setCategoryId(category.id)}}>
                            <ModeOutlined sx={{
                                fontSize: 20,
                                color: "#004159"
                            }}/>
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => {setIsOpenDelete(true); setDeleteId(category.id)}}>
                            <DeleteOutlineOutlined sx={{
                                fontSize: 20,
                                color: "#004159"
                            }}/>
                        </IconButton>
                    </div>
                </div>
            )) : null}
            <DialogComponent
                onClose={() => setIsOpenEdit(false)}
                open={isOpenEdit}
                title={categoryId && categoryId !== "new" ? "Edit category" : "Add category"}
                content={<ContentCategory id={categoryId} closeFunc={() => {setIsOpenEdit(false); getCategories()}}/>}
                form={true}
                buttons={categoryId ? [
                    {
                        variant: "contained",
                        label: "Delete",
                        color: "error",
                        onClick: () => {setIsOpenDelete(true); setDeleteId(categoryId)}
                    },
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "primary",
                        onClick: () => {setIsOpenEdit(false)},
                    }
                ] : [
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "primary",
                        onClick: () => {setIsOpenEdit(false)},
                    }
                ]}     
            />
            <DialogComponent
                open={isOpenDelete}
                onClose={() => {setIsOpenDelete(false)}}
                content={"Are you sure you want to delete this category?"}
                buttons={[
                    {
                        variant: "contained",
                        label: "Delete",
                        color: "error",
                        onClick: () => del(`${appUrl}/api/content-category/${deleteId}`, () => {setIsOpenDelete(false); getCategories(); setIsOpenEdit(false)}, () => {})
                    },
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "primary",
                        onClick: () => setIsOpenDelete(false),
                    }
                ]}
            />
            <DialogComponent
                open={isOpenBulkDelete}
                onClose={() => {setIsOpenBulkDelete(false)}}
                content={selected.length > 0 ? isLoadingDelete ? "" : "Are you sure you want to delete these categories?" : "You have not selected any categories."}
                isLoading={isLoadingDelete}
                buttons={
                    selected.length > 0 ? 
                    [
                        {
                            variant: "contained",
                            label: "Delete",
                            color: "error",
                            onClick: () => deleteCategories()
                        },
                        {
                            variant: "outlined",
                            label: "Cancel",
                            color: "primary",
                            onClick: () => setIsOpenBulkDelete(false),
                        }
                    ] : [
                        {
                            variant: "contained",
                            label: "OK",
                            color: "primary",
                            onClick: () => setIsOpenBulkDelete(false)
                        }    
                    ]
                }
            />
        </div>
    );
}

export default ContentCategories;