import "./Settings.css"
import { useState } from "react";
import { useSelector } from "react-redux";
import { appUrl, post } from "../../utils/fetch";
import Tabs from "../navigation/Tabs";
import { useParams } from "react-router-dom";
import { Cached, FormatListBulletedOutlined, LocalOfferOutlined, Search, Security, Speed, Translate, ViewQuilt, WorkspacePremium } from "@mui/icons-material";
import SettingsAppearance from "./SettingsAppearance";
import TubeSubmit from "../form/TubeSubmit";
import SettingsSeo from "./SettingsSeo";
import SettingsTags from "./SettingsTags";
import SettingsCategories from "./SettingsCategories";

export default function Settings(props){
    const { tab } = useParams();
    const [ isLoadingSubmit, setIsLoadingSubmit ] = useState(false);
    const settings = useSelector((state) => state.settings);
    const settingsMainMenu = useSelector((state) => state.settingsMainMenu);

    const noSaveTabs = ["tags"];
    
    const submit = () => {
        post(
            `${appUrl}/api/settings`,
            settings,
            () => setIsLoadingSubmit(false),
            () => setIsLoadingSubmit(true)
        );
    }

    return (
        <div className="settings">
            <Tabs 
                title={"Settings"}
                className={"settings-tabs"}
                tabs={
                    [
                        { 
                            title: "Appearance",
                            icon: <ViewQuilt/>,
                            href: "/settings/appearance",
                            active: tab === "appearance",
                            content: (
                                <SettingsAppearance isLoading={isLoadingSubmit} />
                            )
                        }, 
                        { 
                            title: "SEO & Analytics",
                            icon: <Search/>,
                            href: "/settings/seo-and-analytics",
                            active: tab === "seo-and-analytics",
                            content: (
                                <SettingsSeo isLoading={isLoadingSubmit} />
                            )
                        },    
                        { 
                            title: "Performance",
                            icon: <Speed/>,
                            href: "/settings/performance",
                            active: tab === "performance",
                            content: (
                                <div/>
                            )
                        },    
                        { 
                            title: "Backups",
                            icon: <Cached/>,
                            href: "/settings/backups",
                            active: tab === "backups",
                            content: (
                                <div/>
                            )
                        },    
                        { 
                            title: "Security",
                            icon: <Security/>,
                            href: "/settings/security",
                            active: tab === "security",
                            content: (
                                <div/>
                            )
                        },    
                        { 
                            title: "Localization",
                            icon: <Translate/>,
                            href: "/settings/localization",
                            active: tab === "localization",
                            content: (
                                <div/>
                            )
                        },    
                        { 
                            title: "Licenses",
                            icon: <WorkspacePremium/>,
                            href: "/settings/licenses",
                            active: tab === "licenses",
                            content: (
                                <div/>
                            )
                        },  
                        { 
                            title: "Categories",
                            icon: <FormatListBulletedOutlined/>,
                            href: "/settings/categories",
                            active: tab === "categories",
                            content: (
                                <SettingsCategories />
                            )
                        },   
                        { 
                            title: "Tags",
                            icon: <LocalOfferOutlined/>,
                            href: "/settings/tags",
                            active: tab === "tags",
                            content: (
                                <SettingsTags />
                            )
                        },   
                    ]
                }
            />
            {!noSaveTabs.includes(tab) && (
                <TubeSubmit
                    label={"Save"}
                    onClick={submit}
                    isLoading={isLoadingSubmit}
                    sx={{
                        marginTop: "10px"
                    }}
                />
            )}
        </div>
    );
}