import DialogComponent from "../dialog/Dialog";
import TubeSubmit from "../form/TubeSubmit";
import Title from "../navigation/Title";
import React, { useState } from "react";
import SettingsTagsDetail from "./SettingsTagsDetail";
import DataTable from "../dataTable/DataTable";
import { appUrl } from "../../utils/fetch";
import { edit } from "../../slices/settingsTagsFilterSlice";
import { edit as selected } from '../../slices/settingsTagsSelectedSlice';
import { useNavigate, useParams } from "react-router-dom";
import SettingsTagsFilter from "./SettingsTagsFilter";

export default function SettingsTags(props){
    const { page } = useParams();
    const navigate = useNavigate();

    const [ detail, setDetail ] = useState({
        isOpen: false,
        id: 0
    });

    const handleOpenDetail = (id = 0) => {
        setDetail({
            isOpen: true,
            id: id
        })
    }

    const handleCloseDetail = () => {
        setDetail({
            isOpen: false,
            id: 0
        })
    }

    return (
        <div className="settings-tags">
            <div className="settings-title-wrapper">
                <Title
                    title="Tags"
                    fontSize={22}
                    sx={{
                        marginTop: "20px"
                    }}
                />
                <TubeSubmit
                    label={"New item"}
                    icon={"add"}
                    onClick={() => handleOpenDetail()}
                />
            </div>
            <SettingsTagsFilter/>
            <DataTable 
                title="Pages"
                dataUrl={`${appUrl}/api/content-tags-cpanel`}
                deleteUrl={`${appUrl}/api/content-tag-delete`}
                editUrl={`/content-tag`}
                url={"/settings/tags"}
                filterStateName="settingsTagsFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/content`}
                selectedStateName="settingsTagsSelected"
                dispatchFunc={selected}
                editOpenFunc={() => navigate(`/settings/tags/page/${page ? page : 1}`)}
                editComponent={(id, closeFunc) => {
                    return (
                        <SettingsTagsDetail id={id} closeFunc={() => closeFunc()}/>
                    )
                }}
                header={{
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    name: {
                        label: "Title",
                        field: "text"
                    },
                    title_slug: {
                        label: "Slug",
                        field: "text"
                    },
                    status: {
                        label: "Status",
                        field: "text",
                        conditionalClass: {
                            content: "Offline",
                            className: "videos-list-status-offline"
                        }
                    },
                    videos: {
                        label: "Videos",
                        field: "text",
                        noSort: true
                    },
                    albums: {
                        label: "Albums",
                        field: "text",
                        noSort: true
                    },
                    channels: {
                        label: "Channels",
                        field: "text",
                        noSort: true
                    },
                    created_at: {
                        label: "Created",
                        field: "text"
                    },
                    seo_title: {
                        label: "SEO title",
                        field: "text"
                    },
                    seo_description: {
                        label: "SEO description",
                        field: "text"
                    },
                    seo_keywords: {
                        label: "SEO keywords",
                        field: "text"
                    }
                }}
            />
            <DialogComponent
                onClose={handleCloseDetail}
                open={detail.isOpen}
                title={detail.id > 0 ? "Edit Tag" : "Create Tag"}
                content={<SettingsTagsDetail id={detail.id} closeFunc={handleCloseDetail}/>}
                buttons={[
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "primary",
                        onClick: handleCloseDetail,
                    }
                ]}
                isLoading={false}
                form  
            />
        </div>
    );
}