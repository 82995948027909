import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: 0,
    name: "",
    slug: "",
    description: "",
    status: 1,
    seo_title: "",
    seo_keywords: "",
    seo_description: "",
    videos: 0,
    albums: 0
}

export const settingsTagsDetailSlice = createSlice({
    name: 'settingsTagsDetail',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = settingsTagsDetailSlice.actions

export default settingsTagsDetailSlice.reducer