import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    type: null,
    source: '',
    file: '',
    status: 1,
    scheduled_from: null,
    scheduled_to: null,
    categories: []
}

export const advertisementSlice = createSlice({
    name: 'advertisement',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = advertisementSlice.actions

export default advertisementSlice.reducer