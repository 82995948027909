import { createSlice } from '@reduxjs/toolkit'

const initialState = {
}

export const historyFilterSlice = createSlice({
    name: 'historyFilter',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            state = {
                ...state, 
                ...payload.payload
            };
            return state;
        }
    },
})

export const { edit } = historyFilterSlice.actions

export default historyFilterSlice.reducer