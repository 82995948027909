import DialogComponent from "../dialog/Dialog";
import TubeSubmit from "../form/TubeSubmit";
import Title from "../navigation/Title";
import React, { useState } from "react";
import ContentCategory from "./ContentCategory";
import DataTable from "../dataTable/DataTable";
import { appUrl } from "../../utils/fetch";
import { edit } from "../../slices/settingsCategoriesFilterSlice";
import { edit as selected } from '../../slices/settingsCategoriesSelectedSlice';
import { useNavigate, useParams } from "react-router-dom";
import SettingsCategoriesFilter from "./SettingsCategoriesFilter";

export default function SettingsCategories(props){
    const { page } = useParams();
    const navigate = useNavigate();

    const [ detail, setDetail ] = useState({
        isOpen: false,
        id: "new"
    });

    const handleOpenDetail = (id = "new") => {
        setDetail({
            isOpen: true,
            id: id
        })
    }

    const handleCloseDetail = () => {
        setDetail({
            isOpen: false,
            id: "new"
        })
    }

    return (
        <div className="settings-categories">
            <div className="settings-title-wrapper">
                <Title
                    title="Categories"
                    fontSize={22}
                    sx={{
                        marginTop: "20px"
                    }}
                />
                <TubeSubmit
                    label={"New item"}
                    icon={"add"}
                    onClick={() => handleOpenDetail()}
                />
            </div>
            <SettingsCategoriesFilter/>
            <DataTable 
                title="Categories"
                dataUrl={`${appUrl}/api/content-categories-cpanel`}
                deleteUrl={`${appUrl}/api/content-category-delete`}
                editUrl={`/content-category`}
                url={"/settings/categories"}
                filterStateName="settingsCategoriesFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/content-categories`}
                selectedStateName="settingsCategoriesSelected"
                dispatchFunc={selected}
                editOpenFunc={() => navigate(`/settings/categories/page/${page ? page : 1}`)}
                editComponent={(id, closeFunc) => {
                    return (
                        <ContentCategory id={id} closeFunc={() => closeFunc()}/>
                    )
                }}
                header={{
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    thumb: {
                        label: "Thumbnail",
                        field: "image",
                        noSort: true
                    },
                    name: {
                        label: "Title",
                        field: "text"
                    },
                    title_slug: {
                        label: "Slug",
                        field: "text"
                    },
                    status: {
                        label: "Status",
                        field: "text",
                        conditionalClass: {
                            content: "Offline",
                            className: "videos-list-status-offline"
                        }
                    },
                    parent: {
                        label: "Parent",
                        field: "list",
                        noSort: true
                    },
                    videos: {
                        label: "Videos",
                        field: "text",
                        noSort: true
                    },
                    albums: {
                        label: "Albums",
                        field: "text",
                        noSort: true
                    },
                    channels: {
                        label: "Channels",
                        field: "text",
                        noSort: true
                    },
                    created_at: {
                        label: "Created",
                        field: "text"
                    },
                    tags: {
                        label: "Tags",
                        field: "list",
                        noSort: true
                    },
                    seo_title: {
                        label: "SEO title",
                        field: "text"
                    },
                    seo_description: {
                        label: "SEO description",
                        field: "text"
                    },
                    seo_keywords: {
                        label: "SEO keywords",
                        field: "text"
                    }
                }}
            />
            <DialogComponent
                onClose={handleCloseDetail}
                open={detail.isOpen}
                title={detail.id !== "new" ? "Edit Category" : "Create Category"}
                content={<ContentCategory id={detail.id} closeFunc={handleCloseDetail}/>}
                buttons={[
                    {
                        variant: "outlined",
                        label: "Cancel",
                        color: "primary",
                        onClick: handleCloseDetail,
                    }
                ]}
                isLoading={false}
                form  
            />
        </div>
    );
}