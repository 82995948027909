import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { slugify as slugifyFunc } from '../../utils/fetch';
import './TextInput.css';
import { IconButton, InputAdornment } from '@mui/material';

export default function TextInput(props){
    const {
        label,
        stateName,
        valueName,
        dispatchFunc,
        disabled,
        readOnly,
        type,
        sx,
        noRedux,
        noReduxOnChange,
        noReduxValue,
        onClick,
        onBlur,
        className,
        slugify,
        onKeyDown,
        errorMsg,
        max,
        description,
        subvalueName,
        adornmentLabel,
        adornmentOnClick,
        adornmentIcon,
        helperText
    } = props;

    const safeStateName = stateName || 'default';
    let value = useSelector((state) => 
        subvalueName 
            ? state[safeStateName]?.[valueName]?.[subvalueName] 
            : state[safeStateName]?.[valueName]
    );
    let subvalue = useSelector((state) => 
        subvalueName 
            ? state[safeStateName]?.[valueName]
            : null
    );
    let valueToSlug = useSelector((state) => {
        return slugify ? state[safeStateName]?.[slugify] : null;
    });
    const dispatch = useDispatch();
    const prevValueToSlugRef = useRef();

    useEffect(() => {
        if (prevValueToSlugRef.current !== valueToSlug) {
            if (valueToSlug) {
                const slugifiedValue = slugifyFunc(valueToSlug);
                if (!value || slugifyFunc(prevValueToSlugRef.current) === value) {
                    handleChange(slugifiedValue);
                }
            }
            prevValueToSlugRef.current = valueToSlug;
        }
    }, [valueToSlug]);

    let handleChange;
    if (noRedux) {
        value = noReduxValue;
        handleChange = (value) => {
            noReduxOnChange(value);
        }
    }
    else {
        handleChange = (value) => {
            if (subvalueName) {
                dispatch(dispatchFunc({ [valueName]: {...subvalue, [subvalueName]: value} }));
            } else {
                dispatch(dispatchFunc({ [valueName]: value }));
            }
        }
    }
    
    return (
        <div className='text-input-wrapper' style={sx}>
            <div style={{
                display: 'inline-flex',
                flexDirection: 'column',
                width: '100%'
            }}>
                <TextField 
                    id={valueName}
                    label={label}
                    variant="outlined"
                    type={type ? type : "text"}
                    className={`text-input ${className}`}
                    value={value || ""}
                    disabled={disabled}
                    onChange={(e) => handleChange(e.target.value)}
                    onClick={onClick}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    autoComplete={"off"}
                    inputProps={{
                        maxLength: max || undefined,
                        readOnly: readOnly,
                    }}
                    InputProps={{
                        endAdornment: 
                            adornmentIcon && (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={adornmentLabel}
                                    onClick={adornmentOnClick}
                                    edge="end"
                                >
                                    {adornmentIcon}
                                </IconButton>
                            </InputAdornment>
                            ),
                    }}
                    sx={{
                        width: "100%",
                    }}
                    helperText={helperText}
                />
                {errorMsg && value && className === "internal-error" ? (<span className='form-error-msg'>{errorMsg}</span>) : null}
            </div>
            {description && (
                <div className='text-input-description'>{description}</div>
            )}
        </div>
    );
}

TextInput.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string,
    valueName: PropTypes.string,
    dispatchFunc: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    sx: PropTypes.object,
    onClick: PropTypes.func,
    onBlur: PropTypes.func
};