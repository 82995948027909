import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "./SelectImage.css";
import TubeSubmit from './TubeSubmit';
import DialogComponent from '../dialog/Dialog';

export default function SelectImage(props){
    const { label, stateName, valueName, dispatchFunc, data, sx, errorMsg } = props;
    const value = useSelector((state) => state[stateName][valueName]);
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = React.useState(false);
    
    const handleChange = (value) => {
        dispatch(dispatchFunc({[valueName]: value}));
    }

    return (
        <div className='tube-select-image' style={sx}>
            <div className='tube-select-image-label'>{label}</div>
            <div 
                className='tube-select-image-placeholder'
                style={!value ? {
                    height: 200
                } : {}}
            >
                {value && (
                    <img src={require(`../../assets/${data[value].src}`)} alt={data[value].name} />
                )}
                <TubeSubmit
                    onClick={() => setIsOpen(true)}
                    label={value ? "Change option" : "Select option"}
                    variant={"outlined"}
                />
            </div>
            <DialogComponent
                onClose={() => setIsOpen(false)}
                open={isOpen}
                title={"Select option"}
                content={(
                    <div className='tube-select-image-dialog-content'>
                        {data && Object.keys(data).map(key => (
                            <div 
                                key={key}
                                className='tube-select-image-item'
                                onClick={() => {
                                    handleChange(key);
                                    setIsOpen(false);
                                }}
                            >
                                <div className='tube-select-image-item-name'>{data[key].name}</div>
                                <img src={require(`../../assets/${data[key].src}`)} alt={data[key].name} />
                            </div>
                        ))}
                    </div>
                )}
                buttons={[
                    {
                        variant: "outlined",
                        label: "Close",
                        color: "primary",
                        onClick: () => setIsOpen(false),
                    }
                ]}
            />
        </div>
    );
}
