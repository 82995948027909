import TextInput from "../form/TextInput";
import { Grid } from "@mui/material";
import { edit } from '../../slices/advertisementsFilterSlice';
import "./AdvertisementsFilter.css";
import MultiSelect from "../form/MultiSelect";
import ButtonSwitch from "../form/ButtonSwitch";

function AdvertisementsFilter() {
    return (
        <div className="advertisements-filter" style={{
            borderRadius: "17px",
            padding: "17px 24px",
            backgroundColor: "#f2f4f8",
            marginTop: "20px"
        }}>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <TextInput
                        stateName="advertisementsFilter"
                        label="Search"
                        valueName="name"
                        dispatchFunc={edit}
                    />
                </Grid>
                <Grid item md={12}>
                    <MultiSelect
                        stateName={"advertisementsFilter"}
                        valueName={"categories"}
                        dispatchFunc={edit}
                        label={"Categories"}
                        data={"content-categories-search"}
                    />
                </Grid>
                <Grid item md={12}>
                    <ButtonSwitch
                        stateName="advertisementsFilter"
                        label="Status"
                        valueName="status"
                        dispatchFunc={edit}
                        data={[
                            { id: 0, label: "All" },
                            { id: 1, label: "Active" },
                            { id: 2, label: "Inactive" }
                        ]}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default AdvertisementsFilter;