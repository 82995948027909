import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import "./Select.css";

export default function SelectComponent(props) {
    const { label, stateName, valueName, dispatchFunc, data, disabled, noRedux, noReduxValue, sx, noReduxOnChange, onChange, subvalueName } = props;
    const safeStateName = stateName || 'default';
    let value = useSelector((state) => 
        subvalueName 
            ? state[safeStateName]?.[valueName]?.[subvalueName] 
            : state[safeStateName]?.[valueName]
    );
    let subvalue = useSelector((state) => 
        subvalueName 
            ? state[safeStateName]?.[valueName]
            : null
    );
    let handleChange;
    if (noRedux) {
        value = noReduxValue;
        handleChange = (value) => {
            noReduxOnChange(value);
            if(onChange){
                onChange(value);
            }
        }
    }
    else {
        handleChange = (value) => {
            if (subvalueName) {
                dispatch(dispatchFunc({ [valueName]: {...subvalue, [subvalueName]: value} }));
            } else {
                dispatch(dispatchFunc({ [valueName]: value }));
            }
        }
    }
    const dispatch = useDispatch();

    React.useEffect(() => {
        if(value){
            handleChange(value);
        }
    }, [value])

    return (
        <FormControl fullWidth className='select-input' sx={sx}>
            <InputLabel id={valueName}>{label}</InputLabel>
            <Select
                labelId={valueName}
                id={`${valueName}-select`}
                value={value || 0}
                label={label}
                disabled={disabled}
                onChange={(e) => handleChange(e.target.value)}
            >
                {Object.keys(data).map(key => (
                    <MenuItem value={key} key={key}>
                        {data[key]}
                    </MenuItem>    
                ))}
            </Select>
        </FormControl>
    );
}

SelectComponent.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string,
    valueName: PropTypes.string,
    dispatchFunc: PropTypes.func,
    data: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};