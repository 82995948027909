import { configureStore } from '@reduxjs/toolkit';
import usersFilterReducer from './slices/usersFilterSlice';
import usersGroupsFilterReducer from './slices/usersGroupsFilterSlice';
import userReducer from './slices/userSlice';
import usersGroupReducer from './slices/usersGroupSlice';
import contentCategoryReducer from './slices/contentCategorySlice';
import contentCategoriesFilterReducer from './slices/contentCategoriesFilterSlice';
import contentTagReducer from './slices/contentTagSlice';
import contentTagsFilterSlice from './slices/contentTagsFilterSlice';
import videoSlice from './slices/videoSlice';
import videosFilterSlice from './slices/videosFilterSlice';
import channelsFilterSlice from './slices/channelsFilterSlice';
import videosSelectedSlice from './slices/videosSelectedSlice';
import usersSelectedSlice from './slices/usersSelectedSlice';
import channelsSelectedSlice from './slices/channelsSelectedSlice';
import grabbersSelectedSlice from './slices/grabbersSelectedSlice';
import albumsSelectedSlice from './slices/albumsSelectedSlice';
import grabbersSourceSlice from './slices/grabbersSourceSlice';
import videosBulkEditSlice from './slices/videosBulkEditSlice';
import albumsBulkEditSlice from './slices/albumsBulkEditSlice';
import importSlice from './slices/importSlice';
import importPhotoSlice from './slices/importPhotoSlice';
import albumSlice from './slices/albumSlice';
import albumsFilterSlice from './slices/albumsFilterSlice';
import progressSlice from './slices/progressSlice';
import metaSlice from './slices/metaSlice';
import channelSlice from './slices/channelSlice';
import modelSlice from './slices/modelSlice';
import modelsFilterSlice from './slices/modelsFilterSlice';
import modelsSelectedSlice from './slices/modelsSelectedSlice';
import pageSlice from './slices/pageSlice';
import pagesFilterSlice from './slices/pagesFilterSlice';
import pagesSelectedSlice from './slices/pagesSelectedSlice';
import historyFilterSlice from './slices/historyFilterSlice';
import advertisementsFilterSlice from './slices/advertisementsFilterSlice';
import settingsSlice from './slices/settingsSlice';
import advertisementSlice from './slices/advertisementSlice';
import advertisementsSelectedSlice from './slices/advertisementsSelectedSlice';
import settingsMainMenuSlice from './slices/settingsMainMenuSlice';
import settingsMainMenuSchemaSlice from './slices/settingsMainMenuSchemaSlice';
import settingsTagsDetailSlice from './slices/settingsTagsDetailSlice';
import settingsTagsFilterSlice from './slices/settingsTagsFilterSlice';
import settingsTagsSelectedSlice from './slices/settingsTagsSelectedSlice';
import settingsCategoriesFilterSlice from './slices/settingsCategoriesFilterSlice';
import settingsCategoriesSelectedSlice from './slices/settingsCategoriesSelectedSlice';

export default configureStore({
    reducer: {
        usersFilter: usersFilterReducer,
        usersGroupsFilter: usersGroupsFilterReducer,
        user: userReducer,
        usersGroup: usersGroupReducer,
        contentCategory: contentCategoryReducer,
        contentCategoriesFilter: contentCategoriesFilterReducer,
        contentTag: contentTagReducer,
        contentTagsFilter: contentTagsFilterSlice,
        video: videoSlice,
        import: importSlice,
        importPhoto: importPhotoSlice,
        videosFilter: videosFilterSlice,
        channelsFilter: channelsFilterSlice,
        videosSelected: videosSelectedSlice,
        usersSelected: usersSelectedSlice,
        channelsSelected: channelsSelectedSlice,
        grabbersSelected: grabbersSelectedSlice,
        albumsSelected: albumsSelectedSlice,
        grabbersSource: grabbersSourceSlice,
        videosBulkEdit: videosBulkEditSlice,
        albumsBulkEdit: albumsBulkEditSlice,
        album: albumSlice,
        albumsFilter: albumsFilterSlice,
        model: modelSlice,
        modelsFilter: modelsFilterSlice,
        modelsSelected: modelsSelectedSlice,
        page: pageSlice,
        pagesFilter: pagesFilterSlice,
        pagesSelected: pagesSelectedSlice,
        selectedVideos: [],
        progress: progressSlice,
        meta: metaSlice,
        channel: channelSlice,
        historyFilter: historyFilterSlice,
        advertisementsFilter: advertisementsFilterSlice,
        advertisement: advertisementSlice,
        advertisementsSelected: advertisementsSelectedSlice,
        settings: settingsSlice,
        settingsMainMenu: settingsMainMenuSlice,
        settingsMainMenuSchema: settingsMainMenuSchemaSlice,
        settingsTagsDetail: settingsTagsDetailSlice,
        settingsTagsFilter: settingsTagsFilterSlice,
        settingsTagsSelected: settingsTagsSelectedSlice,
        settingsCategoriesFilter: settingsCategoriesFilterSlice,
        settingsCategoriesSelected: settingsCategoriesSelectedSlice,
        default: {}
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});