import { Grid } from "@mui/material";
import { useSelector } from 'react-redux';

export default function SettingsTagsDetailStats(props) {
    const tagData = useSelector((state) => state.settingsTagsDetail);
    return (
        <div className="video-card" style={{marginBottom: "20px"}}>
            <Grid container>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Videos</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{tagData.videos}</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Albums</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{tagData.albums}</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Channels</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{tagData.channels}</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-name'>Created</div>
                </Grid>
                <Grid item md={6}>
                    <div className='video-card-data-value'>{tagData.created}</div>
                </Grid>
            </Grid>
        </div>
    )
}