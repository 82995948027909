import TextInput from "../form/TextInput";
import { Grid } from "@mui/material";
import { edit } from '../../slices/settingsCategoriesFilterSlice';

export default function SettingsCategoriesFilter() {
    return (
        <div className="settings-categories-filter" style={{
            borderRadius: "17px",
            padding: "17px 24px",
            backgroundColor: "#f2f4f8",
            marginTop: "20px"
        }}>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <TextInput
                        stateName="settingsCategoriesFilter"
                        label="Search"
                        valueName="name"
                        dispatchFunc={edit}
                    />
                </Grid>
            </Grid>
        </div>
    );
}