import Form from "../form/Form";
import { edit } from '../../slices/advertisementSlice';
import { appUrl } from "../../utils/fetch";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

function Advertisement(props) {
    const { id, closeFunc } = props;
    const navigate = useNavigate();

    return (
        <Form 
            inputs={{
                type: {
                    label: "Placement of advertising",
                    field: "selectImage",
                    width: 6,
                    data: {
                        1: {
                            name: "Placement 1",
                            src: "ad1.jpg"
                        },
                        2: {
                            name: "Placement 1",
                            src: "ad2.jpg"
                        },
                        3: {
                            name: "Placement 1",
                            src: "ad3.jpg"
                        },
                        4: {
                            name: "Placement 1",
                            src: "ad4.jpg"
                        },
                        5: {
                            name: "Placement 1",
                            src: "ad5.jpg"
                        },
                        6: {
                            name: "Placement 1",
                            src: "ad6.jpg"
                        },
                    },
                    merged: {
                    }
                },
                name: {
                    field: "text",
                    label: "Name",
                    width: 6,
                    merged: {
                            source: {
                                field: "textarea",
                                label: "Advertisement source code"
                            },
                            status: {
                                label: "Status",
                                field: "buttonswitch",
                                data: [
                                    { id: 1, label: "Active", color: "green" },
                                    { id: 2, label: "Inactive", color: "red" },
                                ]
                            },
                            categories: {
                                label: "Categories",
                                field: "multiselect",
                                data: "content-categories-search",
                                extraFuncType: "addNew",
                                extraFuncName: "categories",
                                extraFuncLabel: "New category"
                            },
                            scheduled_from: {
                                label: "Active since",
                                field: "date",
                                time: true,
                                sx: {
                                    width: "calc((100% - 10px) / 2)",
                                    display: "inline-block"
                                }
                            },   
                            scheduled_to: {
                                label: "Active until",
                                field: "date",
                                time: true,
                                sx: {
                                    width: "calc((100% - 10px) / 2)",
                                    display: "inline-block",
                                    marginLeft: "10px"
                                }
                            },   
                            empty: {
                                field: "empty",
                                height: 40
                            } 
                        }
                    }
                }
            }
            columns={2}
            stateName="advertisement"
            dispatchFunc={edit}
            title={id !== "new" ? "Edit Ad" : "Create Ad"}
            api={`${appUrl}/api/ad`}
            navbar
            doneFunc={() => { if(closeFunc){closeFunc()} else {navigate(`/advertisement/list/page/1`)} }}
            detailId={id}
            required={[ "name" ]}
            validation={[ "notNull" ]}
        />
    );
}

export default Advertisement;

Advertisement.propTypes = {
    id: PropTypes.number
};