import { DragHandle } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import "./TubeDragAndDrop.css";

export default function TubeDragAndDrop(props){
    const { items, droppableId, itemsObject, setItemsObject } = props;

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;
        const reorderedItems = Array.from(items);
        const [removed] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, removed);
        const newOrder = reorderedItems.map((item) => item.id);
        let reorderedObject = {};
        newOrder.forEach((item, index) => {
            reorderedObject = {
                ...reorderedObject,
                [index + 1]: itemsObject[item]
            }
        })
        setItemsObject(reorderedObject);
      };
    
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={droppableId}>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {items && items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="tube-drag-and-drop-item"
                            >
                                <DragHandle className="drag-icon"/>
                                {item.content}
                            </div>
                            )}
                        </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}