import Form from "../form/Form";
import { edit } from '../../slices/settingsTagsDetailSlice';
import { appUrl } from "../../utils/fetch";
import SettingsTagsDetailStats from "./SettingsTagsDetailStats";

function SettingsTagsDetail(props) {
    const { id, closeFunc } = props;
    return (
        <Form 
            inputs={{
                name: {
                    label: "Title",
                    field: "text",
                    width: 6,
                    merged: {
                        slug: {
                            label: "Slug",
                            field: "text",
                            slugify: "name"
                        },
                        description: {
                            label: "Description",
                            field: "textarea" 
                        },
                        border1: {
                            field: "html",
                            component: <div style={{borderBottom: "1px solid rgba(170, 187, 204, .5)", margin: "10px 0"}} />
                        },
                        status: {
                            label: "Status",
                            field: "buttonswitch",
                            data: [
                                { id: 1, label: "Online", color: "green" },
                                { id: 2, label: "Offline", color: "red" },
                            ]
                        },
                        empty: {
                            field: "empty",
                            height: 40
                        }
                    }
                },
                seo_title: {
                    label: "SEO Title",
                    field: "text",
                    width: 6,
                    merged: {
                        seo_description: {
                            label: "SEO Description",
                            field: "textarea"
                        },
                        seo_keywords: {
                            label: "SEO Keywords",
                            field: "text"
                        },
                        border4: {
                            field: "html",
                            component: <div style={{margin: "15px 0"}} />
                        },
                        stats: {
                            field: "html",
                            component: id ? <SettingsTagsDetailStats /> : null,
                        }
                    }
                },
            }}
            columns={2}
            stateName="settingsTagsDetail"
            dispatchFunc={edit}
            title={id && id !== "new" ? "Edit Tag" : "Create Tag"}
            api={`${appUrl}/api/content-tag`}
            navbar
            doneFunc={closeFunc}
            detailId={id}
            required={[ "name" ]}
            validation={[ "notNull" ]}
        />
    );
}

export default SettingsTagsDetail;