import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Stack } from '@mui/system';
import TextField from '@mui/material/TextField';
import './Upload.css';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export default function TubeUpload(props){
    const { label, stateName, valueName, dispatchFunc, disabled, sx, buttonLabel, noInput, thumbnail, className, description, fileUrl, disableDelete, accept } = props;
    const file = useSelector((state) => state[stateName][valueName]);
    const currentFile = useSelector((state) => state[stateName][`current_${valueName}`]);
    const dispatch = useDispatch();
    const [ currentFileUrl, setCurrentFileUrl ] = React.useState(`${fileUrl}?timestamp=${new Date().getTime()}`);

    const buttonRef = React.useRef();

    const [fileName, setFileName ] = React.useState("");

    useEffect(() => {
        if(file && file.name){
            setFileName(file.name);
        }
        else {
            setFileName("");
        }
    }, [file]);

    const handleChange = (value) => {
        dispatch(dispatchFunc({[valueName]: value}));
        dispatch(dispatchFunc({[`current_${valueName}`]: URL.createObjectURL(value)}));
        setCurrentFileUrl(URL.createObjectURL(value));
    }

    const handleDelete = () => {
        dispatch(dispatchFunc({[valueName]: ""}));
        dispatch(dispatchFunc({[`current_${valueName}`]: ""}));
    }
    
    return (
        <Stack direction={"row"} spacing={3} sx={{
            height: 1,
            ...sx,
            flexDirection: noInput ? "column" : "row"
        }}>
            {!noInput ? <TextField 
                id={valueName}
                label={label}
                variant="outlined"
                type={"text"}
                className={`text-input text-input-upload ${className}`}
                value={fileName}
                disabled={disabled}
                onChange={(e) => handleChange(e.target.value)}
                sx={{
                    width: "auto"
                }}
                onClick={() => buttonRef.current.click()}
            /> : <div className='upload-label'>{label}</div>}
            <div style={{marginLeft: 0, flexDirection: "row", display: "flex", alignItems: "center"}}>
                {thumbnail && (
                    <div className='upload-thumbnail' style={{width: thumbnail.w, height: thumbnail.h}}>
                        <img src={currentFileUrl || currentFile} alt={""} />
                    </div>
                )}
                <Button
                    variant="contained"
                    className={`confirm-button outlined ${noInput ? "no-label" : "with-input"} ${thumbnail ? "no-margin-top" : ""} upload-button`}
                    component="label"
                    disabled={disabled}
                    ref={buttonRef}
                >
                    {buttonLabel ? buttonLabel : "Browse"}
                    <input
                        type="file"
                        onChange={(e) => handleChange(e.target.files[0])}
                        hidden
                        id={`input-${valueName}`}
                        accept={accept || "*"}
                    />
                </Button>
                {!disableDelete && (
                    <Button
                        variant="contained"
                        className={`delete-button confirm-button outlined upload-button ${thumbnail ? "no-margin-top" : ""} ${noInput ? "" : "with-input"}`}
                        component="label"
                        disabled={disabled}
                        ref={buttonRef}
                        style={{marginLeft: "10px"}}
                        onClick={handleDelete}
                    >
                        <DeleteOutlineOutlinedIcon/>
                    </Button>
                )}
            </div>
            {description && (
                <div className='tube-upload-description'>{description}</div>
            )}
        </Stack>
    );
}

TubeUpload.propTypes = {
    label: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    valueName: PropTypes.string.isRequired,
    dispatchFunc: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};