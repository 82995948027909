import Form from "../form/Form";
import { edit } from '../../slices/modelSlice';
import { appUrl } from "../../utils/fetch";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import ModelStats from "./ModelStats";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

function Model(props) {
    const { id, closeFunc } = props;
    const navigate = useNavigate();
    const settings = useSelector((state) => state.settings);
    const [ units, setUnits ] = useState(["kg", "cm", "cm"]);
    
    useEffect(() => {
        if(settings?.units === "imperial"){
            setUnits(["lb", "in", "ft"]);
        }
    }, [settings])

    return (
        <Form 
            inputs={{
                    name: {
                    label: "Name",
                    field: "text",
                    width: 6,
                    merged: {
                        stage_name: {
                            label: "Stage name",
                            field: "text"
                        },
                        status: {
                            label: "Profile status",
                            field: "buttonswitch",
                            data: [
                                { id: 1, label: "Active", color: "green" },
                                { id: 2, label: "Inactive", color: "red" },
                            ],
                            width: 12,
                            sx: {
                                borderBottom: "1px solid rgba(170, 187, 204, .5)",
                                paddingBottom: "12px",
                                marginBottom: "24px"
                            }
                        },
                        birth_date: {
                            label: "Birth date",
                            field: "date"
                        },
                        city: {
                            label: "City",
                            field: "text"
                        },
                        country: {
                            label: "Country",
                            field: "multiselect",
                            data: "countries-search",
                            disableMultiple: true
                        },
                        gender: {
                            label: "Gender",
                            field: "select",
                            data: {
                                1: "Male",
                                2: "Female",
                                3: "Couple",
                                4: "Transexual"
                            },
                            width: 6,
                            sx: {
                                width: "calc((100% - 20px) / 3)"
                            }
                        },
                        height: {
                            label: `Height (${units[2]})`,
                            field: "text",
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px"
                            }
                        },
                        weight: {
                            label: `Weight (${units[0]})`,
                            field: "text",
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px"
                            }
                        },
                        tattoos: {
                            label: "Tattoos",
                            field: "select",
                            data: {
                                1: "Yes",
                                2: "No"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 2)"
                            }
                        },
                        piercings: {
                            label: "Piercings",
                            field: "select",
                            data: {
                                1: "Yes",
                                2: "No"
                            },
                            sx: {
                                width: "calc((100% - 10px) / 2)",
                                marginLeft: "10px"
                            }
                        },
                        measurements: {
                            label: `Measurements: Bust(${units[1]}) - Waist(${units[1]}) - Hips(${units[1]})`,
                            field: "text"
                        },
                        hair_color: {
                            label: "Hair color",
                            field: "select",
                            data: {
                                1: "Black",
                                2: "Brown",
                                3: "Blonde",
                                4: "Red",
                                5: "Gray"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)"
                            }
                        },
                        eye_color: {
                            label: "Eye color",
                            field: "select",
                            data: {
                                1: "Brown",
                                2: "Blue",
                                3: "Green",
                                4: "Gray",
                                5: "Black"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px"
                            }
                        },
                        ethnicity: {
                            label: "Ethnicity",
                            field: "select",
                            data: {
                                1: "Asian",
                                2: "Black",
                                3: "Indian",
                                4: "Latino",
                                5: "Middle Eastern",
                                6: "Mixed",
                                7: "White",
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px"
                            }
                        },
                        site_name: {
                            label: "Official site name",
                            field: "text"
                        },
                        url: {
                            label: "Official site URL",
                            field: "text"
                        },
                        biography: {
                            label: "Biography",
                            field: "textarea"
                        },
                        career_status: {
                            label: "Career status",
                            field: "select",
                            data: {
                                1: "Active",
                                2: "Retired"
                            },
                            sx: {
                                width: "calc((100% - 20px) / 3)"
                            }
                        },
                        career_start: {
                            label: "Start of career (year)",
                            field: "text",
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px"
                            }
                        },
                        career_end: {
                            label: "End of career (year)",
                            field: "text",
                            sx: {
                                width: "calc((100% - 20px) / 3)",
                                marginLeft: "10px"
                            }
                        },
                        empty: {
                            field: "empty",
                            height: 50
                        },
                    }
                },
                stats: {
                    field: "html",
                    component: id ? <ModelStats /> : null,
                    width: 6,
                    merged: {
                        orientation: {
                            label: "Orientation",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "Straight",
                                2: "Gay",
                                3: "Lesbian",
                                4: "Not Sure",
                                5: "Bisexual"
                            }
                        },
                        relationship: {
                            label: "Relationship status",
                            field: "select",
                            data: {
                                0: "Rather not say",
                                1: "Single",
                                2: "In a relationship",
                                3: "Married",
                                4: "Engaged",
                                5: "It's complicated",
                                6: "Divorced",
                                7: "Widowed"
                            }
                        },
                        interests: {
                            label: "Interests",
                            field: "text",
                            sx: {
                                marginBottom: "10px"
                            }
                        },
                        coverAndLogo: {
                            field: "cover",
                            width: 6,
                            logo: "current_thumbnail",
                            cover: "current_cover",
                        },
                        thumbnail: {
                            label: "Thumbnail",
                            field: "upload",
                            currentImage: "current_thumbnail",
                            width: 3,
                            sx: {
                                float: "left",
                                width: "calc(50% - 10px)",
                                marginRight: "10px",
                                marginBottom: "30px"
                            },
                            buttonLabel: "Upload Thumbnail",
                            noInput: true
                        },
                        cover: {
                            label: "Cover Image",
                            field: "upload",
                            currentImage: "current_cover",
                            width: 3,
                            buttonLabel: "Upload Image",
                            noInput: true
                        },
                        onlyfans: {
                            label: "OnlyFans",
                            field: "text"
                        },
                        twitter: {
                            label: "Twitter",
                            field: "text"
                        },
                        reddit: {
                            label: "Reddit",
                            field: "text"
                        },
                        amazon: {
                            label: "Amazon Wishlist",
                            field: "text"
                        },
                        instagram: {
                            label: "Instagram",
                            field: "text"
                        },
                        facebook: {
                            label: "Facebook",
                            field: "text"
                        },
                    }
                },
                
            }}
            columns={2}
            stateName="model"
            dispatchFunc={edit}
            title={id !== "new" ? "Edit Model" : "Create Model"}
            api={`${appUrl}/api/model`}
            navbar
            doneFunc={() => { if(closeFunc){closeFunc()} else {navigate(`/models/list/page/1`)} }}
            detailId={id}
            required={[ "name" ]}
            validation={[ "notNull" ]}
        />
    );
}

export default Model;

Model.propTypes = {
    id: PropTypes.number
};