import DataTable from "../dataTable/DataTable";
import { edit as selected } from '../../slices/advertisementsSelectedSlice';
import { edit } from '../../slices/advertisementsFilterSlice';
import { appUrl } from "../../utils/fetch";
import AdvertisementsFilter from "./AdvertisementsFilter";
import Advertisement from "./Advertisement";



function AdvertisementsList() {
    return (
        <div>
            <AdvertisementsFilter />
            <DataTable 
                title="Ads"
                dataUrl={`${appUrl}/api/ads`}
                deleteUrl={`${appUrl}/api/ad-delete`}
                editUrl={`/ad`}
                filterStateName="advertisementsFilter"
                filterDispatchFunc={edit}
                filterUrl={`${appUrl}/api/ads`}
                url={"/advertisement/list"}
                selectedStateName="advertisementsSelected"
                dispatchFunc={selected}
                editComponent={(id, closeFunc) => {
                    return (
                        <div style={{width: "calc(1200px)"}}>
                            <Advertisement id={id} closeFunc={closeFunc}/>
                        </div>
                    )
                }}
                header={{
                    type: {
                        label: "Type",
                        field: "selectImage",
                        alt: "name",
                        data: {
                            1: {
                                name: "Placement 1",
                                src: "ad1.jpg"
                            },
                            2: {
                                name: "Placement 1",
                                src: "ad2.jpg"
                            },
                            3: {
                                name: "Placement 1",
                                src: "ad3.jpg"
                            },
                            4: {
                                name: "Placement 1",
                                src: "ad4.jpg"
                            },
                            5: {
                                name: "Placement 1",
                                src: "ad5.jpg"
                            },
                            6: {
                                name: "Placement 1",
                                src: "ad6.jpg"
                            },
                        }
                    },
                    id: {
                        label: "ID",
                        field: "text"
                    },
                    name: {
                        label: "Name",
                        field: "text"
                    },
                    status: {
                        label: "Status",
                        field: "text",
                        conditionalClass: {
                            content: "Inactive",
                            className: "videos-list-status-offline"
                        }
                    },
                    views: {
                        label: "Views",
                        field: "text"
                    },
                    categories: {
                        label: "Categories",
                        field: "list",
                        noSort: true
                    },
                    scheduled_from: {
                        label: "Scheduled from",
                        field: "text"
                    },
                    scheduled_to: {
                        label: "Scheduled to",
                        field: "text"
                    }
                }}
            />
        </div>
    );
}

export default AdvertisementsList;