import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    schema: []
}

export const settingsMainMenuSchemaSlice = createSlice({
    name: 'settingsMainMenuSchema',
    initialState: initialState,
    reducers: {
        edit: (state, payload) => {
            if(payload.payload === "default"){
                state = initialState;
            }
            else {
                state = {
                    ...state, 
                    ...payload.payload
                };
            }
            return state;
        }
    },
})

export const { edit } = settingsMainMenuSchemaSlice.actions

export default settingsMainMenuSchemaSlice.reducer