import React, { useEffect } from "react";
import Tabs from "../navigation/Tabs";
import { useParams } from "react-router-dom";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdvertisementsList from "./AdvertisementsList";
import { edit } from '../../slices/advertisementSlice';
import { useDispatch } from "react-redux";
import Advertisement from "./Advertisement";

function Advertisements() {
    const dispatch = useDispatch();
    const { tab } = useParams();
     
    useEffect(() => {
        if(tab === "new"){
            dispatch(edit("default"));
        }
    }, [tab]);

    return (
        <React.Fragment>
            <Tabs
                title={"Advertisement"}
                tabs={
                    [
                        { 
                            title: "All ads",
                            icon: <FormatListBulletedIcon />,
                            href: "/advertisement/list",
                            active: tab === "list",
                            content: (
                                <AdvertisementsList />
                            )
                        },
                        { 
                            title: "New ad",
                            icon: <AddCircleOutlineIcon />,
                            href: "/advertisement/new",
                            active: tab === "new",
                            content: (
                                <Advertisement />
                            )
                        }
                    ]
                }
            />
        </React.Fragment>
    );
}

export default Advertisements;