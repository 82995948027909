import TextInput from "../form/TextInput";
import { edit } from "../../slices/settingsSlice";
import Title from "../navigation/Title";
import TextArea from "../form/TextArea";
import React, { useState } from "react";
import { ContentCopy } from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function SettingsSeo(props){
    const { isLoading } = props;

    const [ isCopiedSitemap, setIsCopiedSitemap ] = useState(false);

    const sitemap = useSelector(state => state.settings?.sitemap);

    const copySitemap = () => {
        setIsCopiedSitemap(true);
        setTimeout(() => setIsCopiedSitemap(false), 3000);
        navigator.clipboard.writeText(sitemap);
    }

    return (
        <div className="settings-seo">
            <Title
                title="SEO & Analytics"
                fontSize={22}
                sx={{
                    marginTop: "20px"
                }}
            />
            <div className="settings-card">
                <Title
                    title="Meta tags"
                    fontSize={20}
                    sx={{
                        marginBottom: "10px"
                    }}
                />
                <TextArea
                    label="Descrition"
                    stateName="settings"
                    valueName="seoDescription"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                />
                <TextInput
                    label="Keywords"
                    stateName="settings"
                    valueName="siteKeywords"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                />
            </div>
            <div className="settings-card">
                <Title
                    title="Sitemap"
                    fontSize={20}
                    sx={{
                        marginBottom: "10px"
                    }}
                />
                <TextInput
                    label="Sitemap"
                    stateName="settings"
                    valueName="sitemap"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    readOnly
                    adornmentIcon={<ContentCopy />}
                    adornmentLabel="Copy link"
                    adornmentOnClick={copySitemap}
                    helperText={isCopiedSitemap && "Sitemap url has been copied!"}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                />
            </div>
            <div className="settings-card">
                <Title
                    title="Verification codes"
                    fontSize={20}
                    sx={{
                        marginBottom: "10px"
                    }}
                />
                <TextArea
                    label="Paste code"
                    stateName="settings"
                    valueName="verificationCode"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                />
            </div>
            <div className="settings-card">
                <Title
                    title="Javascript integration"
                    fontSize={20}
                    sx={{
                        marginBottom: "10px"
                    }}
                />
                <TextArea
                    label="Paste code"
                    stateName="settings"
                    valueName="javascriptIntegration"
                    dispatchFunc={edit}
                    disabled={isLoading}
                    description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia."
                />
            </div>
        </div>
    );
}