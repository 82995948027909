import { Avatar, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { appUrl, post } from '../../utils/fetch.js';
import './VideoPreview.css';
import TubeSubmit from '../form/TubeSubmit.js';
import { useEffect, useRef, useState } from 'react';
import User from './User.js';

function VideoPreview(props) {
    const { sx, userDetail } = props;
    const videoData = useSelector((state) => state.video);
    const video = useRef();
    const [ isLoadingVideoThumbnail, setIsLoadingVideoThumbnail ] = useState(false);
    const [ isLoadingCustomVideoThumbnail, setIsLoadingCustomVideoThumbnail ] = useState(false);
    const [ source, setSource ] = useState({
        source: "",
        url: ""
    });
    const [ time, setTime ] = useState(new Date());
    
    useEffect(() => {
        if(videoData.source){
            let sourceTemp = videoData.source.split(";");
            setSource({
                source: sourceTemp[0],
                url: sourceTemp[1]
            });
        }     
    }, [videoData.source]);
    
    return (
        <div style={{...sx,
            maxWidth: "50%"
        }} className={`video-preview`}>
            <div className='video-card'>
                <Grid
                    container 
                    spacing={0}
                >
                    <Grid
                        item
                        md={1}
                    >
                        <Avatar 
                            alt="Dummy User"
                            src={`${appUrl}/${videoData.userAvatar}`}
                        >
                            {videoData.userInitials}
                        </Avatar>
                    </Grid>
                    <Grid
                        item
                        md={10}
                    >
                        <div className='video-card-user-name'>
                            {videoData.user}
                        </div>
                        <div className='video-card-user-registration'>
                            Registered: {videoData.userCreatedAt}
                        </div>
                    </Grid>
                    <Grid
                        item
                        md={1}
                    >
                        <IconButton className='video-card-arrow' onClick={userDetail}>
                            <ArrowForwardIcon fontSize={"inherit"} />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        className={"video-card-line"}
                    >
                        <div />
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-name'>Video ID</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-value'>{videoData.id}</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-name'>Views</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-value'>{videoData.views}</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-name'>Date published</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-value'>{videoData.datePublished}</div>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        className={"video-card-line"}
                    >
                        <div />
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-name'>Resolution</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-value'>{videoData && videoData.videoData ?  videoData.videoData.dimensions : null}</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-name'>Length</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-value'>{videoData.length}</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-name'>Video FPS</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-value'>{videoData.fps}</div>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        className={"video-card-line"}
                    >
                        <div />
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-name'>Imported from</div>
                    </Grid>
                    <Grid
                        item
                        md={6}
                    >
                        <div className='video-card-data-value'>{source.source ? source.source : "Direct upload"}</div>
                    </Grid>
                    {source.url ? (
                        <>
                            <Grid
                                item
                                md={6}
                            >
                                <div className='video-card-data-name'>Original URL</div>
                            </Grid>
                            <Grid
                                item
                                md={6}
                            >
                                <div className='video-card-data-value'><a href={source.url} target="_blank" rel="noreferrer">{source.url}</a></div>
                            </Grid>
                        </>                        
                    ) : ""}
                </Grid>
            </div>
            <div className='title-14' style={{
                marginTop: 20,
                marginBottom: 5
            }}>
                Video Preview
            </div>
            {videoData.embed ? (
                <iframe 
                    className={"video-file"}
                    src={videoData.embed}
                    frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                />
            ) : (
                <video
                    src={`${appUrl}/videos/${videoData.file}`}
                    controls
                    ref={video}
                    className={"video-file"}
                />
            )}
            <div className='title-14' style={{
                marginTop: 20,
                marginBottom: 5
            }}>
                Video thumbnail
            </div>
            <Grid container spacing={2}>
                <Grid item>
                    <video
                        src={`${appUrl}/videos/thumbs/preview-${videoData.file}`}
                        className='video-preview-thumbnail'
                        autoPlay
                        loop
                        width={150}
                        height={85}
                    />
                </Grid>
                <Grid item>
                    <div
                        style={{backgroundImage: videoData.thumbnail === "no-preview" ? "none" : `url('${appUrl}/videos/thumbs/${videoData.thumbnail}?t=${time}')`}}
                        className='video-preview-thumbnail'
                    >
                        {videoData.thumbnail === "no-preview" && (
                            <div>
                                No preview
                            </div>
                        )}    
                    </div>
                </Grid>
                <Grid item>
                    <Grid item md={12} sx={{paddingBottom: "5px"}}>
                        <TubeSubmit
                            label="Set current preview frame"
                            variant={"outlined"}
                            isLoading={isLoadingVideoThumbnail}
                            onClick={() => post(`${appUrl}/api/thumbnail-video`, {
                                videoFile: videoData.file,
                                thumbnail: videoData.thumbnail,
                                currentTime: video.current.currentTime
                            }, () => {
                                setIsLoadingVideoThumbnail(false);
                                setTime(new Date());
                            }, () => setIsLoadingVideoThumbnail(true))}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TubeSubmit
                            label={(
                                <>
                                    Upload custom thumbnail
                                    <input
                                        type="file"
                                        onChange={(e) => post(`${appUrl}/api/custom-thumbnail-video`, {
                                            thumbnail: videoData.thumbnail,
                                            file: e.target.files[0]
                                        }, () => {
                                            setIsLoadingCustomVideoThumbnail(false);
                                            setTime(new Date());
                                        }, () => setIsLoadingCustomVideoThumbnail(true))}
                                        hidden
                                        id={`input-custom-thumbnail`}
                                    />
                                </>
                            )}
                            variant={"outlined"}
                            isLoading={isLoadingCustomVideoThumbnail}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default VideoPreview;

VideoPreview.propTypes = {
    sx: PropTypes.object,
};